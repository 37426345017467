@mixin mobile {
  @media (max-width: 620px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 621px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@include mobile {
	.hide--mobile {
		display: none;
	}
}

@font-face {
	font-family: 'Baloo';
	src: url('./fonts/Baloo-Regular.woff2') format('woff2'), url('./fonts/Baloo-Regular.woff') format('woff');
}

* {
	box-sizing: border-box;
}

html, body {
	position: fixed;
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100vh;
	width: 100%;
}
body {
	// font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-family: Arial, sans-serif;
	background-color: #F6F6F8;
}
#home {
	overflow: hidden;
	height: 100vh;
	.wrapper {
		padding: 2rem;
		@include tablet {
			padding: 0;
			max-width: 600px;
			margin: auto;
			padding-top: 5.5rem;
		}
		@include desktop {
			max-width: 900px;
		}
	}
	text-align: center;
	h1 {
		font-size: 14vw;
		white-space: nowrap;
		font-weight: normal;
		letter-spacing: -1px;
		font-family: 'Baloo';
		@include tablet {
			font-size: 3.5rem;
		}
		@include desktop {
			font-size: 4.8rem;
			letter-spacing: -3px;
		}
		.dot {
			color: #F69184;
		}
	}
	h2 {
		font-size: 9.5vw;
		font-weight: normal;
		letter-spacing: 0px;
		line-height: 120%;
		color: #F69184;
		margin: 1.5rem 0;
		@include tablet {
			font-size: 2.4rem;
			margin-bottom: 5rem;
			letter-spacing: -1px;
		}
		@include desktop {
			font-size: 3.4rem;
		}
	}
	p.help {
		font-size: 0.95rem;
		font-weight: normal;
		opacity: 0.4;
		margin-bottom: 1.5rem;
		@include tablet {
			font-size: 1.4rem;
			margin-bottom: 2rem;
		}
		@include desktop {
			font-size: 1.7rem;
			margin-bottom: 2rem;
		}
	}
	.logo {
		display: block;
		margin-top: 1rem;
		img {
			width: 8rem;
			@include tablet {
				width: 11rem;
			}
		}
	}
	.preview {
		margin: auto;
		img {
			filter: grayscale(100%);
			max-width: 727px;
			margin-top: 1rem;
			width: 90%;
			@include tablet {
				margin-top: 5rem;
				width: 80%;
			}
			@include desktop {
				margin-top: 5rem;
				width: 80%;
			}
		}
	}
}
#search {
	form {
		* {
			display: inline-block;
		}
		input {
			margin-bottom: 0.5rem;
		}
		@include tablet {
			display: flex;
			input {
				flex-grow: 1;
			}
			button {
				margin-left: 1rem;
				width: 400px;
			}
		}
	}
}
input {
	border: 1px solid #cccccc;
	border-radius: 10px;
	padding: 1rem;
	font-size: 1.3rem;
	width: 100%;
	text-align: center;
	&::placeholder {
		font-weight: 300;
	}
	&:focus {
		outline: none !important;
	    border:1px solid orange;
	    // box-shadow: 0 0 10px #719ECE;
	}
	@include tablet {
		font-size: 1.8rem;
		text-align: left;
	}
	@include desktop {
		font-size: 2rem;
	}
}
button {
	border: none;
	border-radius: 10px;
	box-shadow: inset 0px -10px 0px 0px rgba(0,0,0,0.15);
	padding: 1.3rem;
	padding-top: 0.7rem;
	font-weight: bold;
	font-size: 1.4rem;
	color: white;
	background-color: #53CB78;
	width: 100%;
	&:disabled {
		background-color: #ccc;
	}
	&:focus {
		outline: none !important;
	}
	span {
		font-size: 0.8em;
		font-weight: 300;
		opacity: 0.8;
		font-style: italic;
		text-decoration: line-through;
	}
	@include tablet {
		font-size: 1.6rem;
	}
	@include desktop {
		font-size: 1.8rem;
	}
}
#popup {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
	padding: 2rem;
	// opacity: 0;
	// transition: 0.5s ease-in;
	.bg-fade {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		padding: 2rem;
		z-index: 100;
	}
	form {
		z-index: 300;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		@include tablet {
			max-width: 550px;
			margin: auto;
		}
	}
	.box {
		position: relative;
		z-index: 400;
		line-height: 150%;
		background-color: white;
		width: 100%;
		padding: 1.5rem;
		border-radius: 10px;
		@include tablet {
			padding: 2.5rem;
		}
		.badge {
			margin: auto;
			background-color: white;
			border-radius: 100%;
			$size: 90px;
			width: $size;
			height: $size;
			box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
			margin-bottom: 1rem;
			margin-top: -4rem;
			padding: 1.4rem;
			img {
				$size: 40px;
				width: $size;
			}
			@include tablet {
				img {
					$size: 50px;
					width: $size;
				}
				$size: 120px;
				width: $size;
				height: $size;
				margin-bottom: 2rem;
				margin-top: -6rem;
				padding: 2rem;
			}
			@include desktop {
				img {
					$size: 60px;
					width: $size;
				}
				$size: 140px;
				width: $size;
				height: $size;
				margin-bottom: 2rem;
				margin-top: -7rem;
				padding: 2.2rem;
			}
		}
		p.header {
			font-size: 1.05rem;
			margin-bottom: 1.2rem;
			padding: 0 0.8rem;
			@include tablet {
				font-size: 1.5rem;
				margin-bottom: 2rem;
			}
		}
		input {
			text-align: center;
		}
		button {
			margin-top: 1rem;
			@include tablet {
				margin-top: 2rem;
			}
		}
		p.small {
			font-size: 0.75rem;
			opacity: 0.5;
			text-align: left;
			line-height: 130%;
			//margin: 1rem 0;
			@include tablet {
				font-size: 0.9rem;
				//margin: 2rem 0;
			}
		}
		.submitted {
			* {
				text-align: center !important;
			}
			p.header {
				// font-size: 0.9rem;
			}
			p.small {
				margin-top: 1rem;			
				@include tablet {
					margin-top: 2rem;
					padding: 0 4rem;
				}
			}
			img {
				width: 50%;
				opacity: 0.5;
				@include tablet {
					width: 30%;
				}
			}
			padding: 1.5rem;
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// background-color: red;
			display: flex;
			align-items: center;
			span {
				margin: auto;
			}
		}
		.checkbox {
			margin-top: 1rem;
			display: flex;
			text-align: left;
			align-items: center;
			@include tablet {
				margin-top: 2rem;
			}
			.input {
				padding-right: 2rem;
				input {
					// margin-top: 11px;
					// margin-left: 10px;
					// transform: scale(2.5);
				}
			}
			.text {
				font-size: 0.8rem;
				line-height: 140%;
				opacity: 0.8;
				@include tablet {
					font-size: 1.1rem;
					margin-left: 1rem;
					line-height: 150%;
				}
			}
			.pro {
				opacity: 0.5;
				color: #F69184;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 1.3rem;
				font-style: italic;
				letter-spacing: -1px;
				margin-left: 1rem;
				p {
					$size: 2.8em;
					width: $size;
					height: $size;
					border-radius: 100%;
					border: 1px solid #F69184;
					padding: 0.8em 0.3em;
				}
			}
		}
	}
}
#confetti {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: -999;
	opacity: 0.5;
}
